import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketCreationService {

  private formData: BehaviorSubject<any> = new BehaviorSubject(null);
  formDataState$: Observable<any> = this.formData.asObservable();

  constructor() { }

  set(data: any) {
    this.formData.next(data);
  }
}
