/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-view-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i4 from "primeng/components/dialog/dialog";
import * as i5 from "./upload-view-modal.component";
import * as i6 from "../../services/ticket-view.service";
import * as i7 from "primeng/components/common/messageservice";
var styles_UploadViewModalComponent = [i0.styles];
var RenderType_UploadViewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadViewModalComponent, data: {} });
export { RenderType_UploadViewModalComponent as RenderType_UploadViewModalComponent };
function View_UploadViewModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "label", [["class", "dashed-border"], ["for", "fileInput"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.storeClickIndex(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Photo/Video"]))], null, null); }
function View_UploadViewModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMedia(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times-circle"]], null, null, null, null, null))], null, null); }
function View_UploadViewModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-center flex-column align-items-center dashed-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-picture-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Preview Found"]))], null, null); }
function View_UploadViewModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "image-preview dashed-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "spinner-border"], ["role", "status"], ["style", "height: 13px; width: 13px; color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["style", "font-size: 12px; margin-left: 10px; color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploading media, Please wait..."]))], null, null); }
function View_UploadViewModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_UploadViewModalComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "video", [["class", "image-preview"], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Browser not supported "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadViewModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_v.context.$implicit && !_co.isUploading[_v.context.index]) && !_co.viewOnly); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit && !_co.viewOnly); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_v.context.$implicit && _co.viewOnly); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isUploading[_v.context.index]; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit && _co.isImage(_v.context.$implicit)); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_v.context.$implicit && !_co.isImage(_v.context.$implicit)); _ck(_v, 12, 0, currVal_5); }, null); }
function View_UploadViewModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "p-dialog", [["closeOnEscape", "true"], ["dismissableMask", "true"]], null, [[null, "visibleChange"], [null, "onShow"], [null, "onHide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.onShow($event) !== false);
        ad = (pd_1 && ad);
    } if (("onHide" === en)) {
        var pd_2 = (_co.onHide($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_Dialog_0, i3.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i4.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], modal: [1, "modal"], closeOnEscape: [2, "closeOnEscape"], dismissableMask: [3, "dismissableMask"] }, { onShow: "onShow", onHide: "onHide", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Attachments"])), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "div", [["class", "upload-containers"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, 1, 3, "input", [["accept", "image/*,video/*"], ["hidden", ""], ["id", "fileInput"], ["type", "file"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.handleFileInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { display: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; var currVal_1 = _co.isModal; var currVal_2 = "true"; var currVal_3 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.uploadContainers; _ck(_v, 8, 0, currVal_4); var currVal_5 = _ck(_v, 12, 0, "none"); _ck(_v, 11, 0, currVal_5); }, null); }
function View_UploadViewModalComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "label", [["class", "dashed-border-s"], ["for", "fileInput"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.storeClickIndex(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"], ["style", "font-size: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["style", "font-size: 12px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Photo/Video"]))], null, null); }
function View_UploadViewModalComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeMedia(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times-circle"]], null, null, null, null, null))], null, null); }
function View_UploadViewModalComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-center flex-column align-items-center dashed-border-s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-picture-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Preview Found"]))], null, null); }
function View_UploadViewModalComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "image-preview dashed-border-s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "spinner-border"], ["role", "status"], ["style", "height: 13px; width: 13px; color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["style", "font-size: 12px; margin-left: 10px; color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploading media, Please wait..."]))], null, null); }
function View_UploadViewModalComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image-preview"], ["style", "height: 200px;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_UploadViewModalComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "video", [["class", "image-preview"], ["controls", ""], ["style", "height: 200px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Browser not supported "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_UploadViewModalComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"], ["style", "height: 200px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_12)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_13)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_14)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_15)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_16)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_v.context.$implicit && !_co.isUploading[_v.context.index]) && !_co.viewOnly); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit && !_co.viewOnly); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_v.context.$implicit && _co.viewOnly); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isUploading[_v.context.index]; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit && _co.isImage(_v.context.$implicit)); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_v.context.$implicit && !_co.isImage(_v.context.$implicit)); _ck(_v, 12, 0, currVal_5); }, null); }
function View_UploadViewModalComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Attachments"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "upload-containers"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_10)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "input", [["accept", "image/*,video/*"], ["hidden", ""], ["id", "fileInput"], ["type", "file"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.handleFileInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { display: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadContainers; _ck(_v, 5, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, "none"); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_UploadViewModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadViewModalComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isModal; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.isModal && _co.visible); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UploadViewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-view-modal", [], null, null, null, View_UploadViewModalComponent_0, RenderType_UploadViewModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.UploadViewModalComponent, [i6.TicketViewService, i7.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadViewModalComponentNgFactory = i1.ɵccf("app-upload-view-modal", i5.UploadViewModalComponent, View_UploadViewModalComponent_Host_0, { visible: "visible", isModal: "isModal", uploadContainers: "uploadContainers", viewOnly: "viewOnly" }, { handleOnShow: "handleOnShow", handleOnHide: "handleOnHide", uploadedFiles: "uploadedFiles" }, []);
export { UploadViewModalComponentNgFactory as UploadViewModalComponentNgFactory };
