<div>
  <div
    [class]="config.className ? config.className : 'dynamicForm'"
    [formGroup]="group"
  >
    <label *ngIf="config.label" [attr.for]="config.htmlId"
      >{{ config.label }}
      <span
        *ngIf="config.validations && config.validations.required"
        class="required_field"
        >*</span
      >
    </label>
    <input
      pInputText
      id="input"
      [attr.id]="config.htmlId"
      [attr.type]="config.htmlType"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
    />
  </div>
  <div *ngIf="config.label === 'Maintenance Description'" class="inputSwitch">
    <span>Injuries</span>
    <p-inputSwitch
      [(ngModel)]="hasInjuries"
      [ngModelOptions]="{ standalone: true }"
      (click)="handleClick($event, 'hasInjuries')"
    ></p-inputSwitch>

    <div *ngIf="hasInjuries" class="left-border"></div>

    <span *ngIf="hasInjuries" class="injury-selection">
      <span>Injuries To:</span>
      <p-checkbox 
        name="injurySelection" 
        label="Driver" 
        (click)="handleClick($event, 'injuryTo')"
        value="driver"
        [(ngModel)]="injuriesTo"
        [ngModelOptions]="{ standalone: true }">
      </p-checkbox>
      <p-checkbox 
        name="injurySelection" 
        label="Rider" 
        (click)="handleClick($event, 'injuryTo')"
        value="rider"
        [(ngModel)]="injuriesTo"
        [ngModelOptions]="{ standalone: true }">
      </p-checkbox>
      <p-checkbox 
        name="injurySelection" 
        label="Third Party" 
        (click)="handleClick($event, 'injuryTo')"
        value="third party"
        [(ngModel)]="injuriesTo"
        [ngModelOptions]="{ standalone: true }">
      </p-checkbox>
    </span>
  </div>
</div>