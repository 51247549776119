import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadViewModalComponent } from './upload-view-modal/upload-view-modal.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [UploadViewModalComponent],
  imports: [
    DialogModule,
    CommonModule
  ],
  exports: [UploadViewModalComponent]
})
export class SharedModule { }
