import { Urls } from "../models/api.constants";
import { Headers } from "../models/api.headers";
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
;
export class TicketViewService {
    constructor(http, cookieService) {
        this.http = http;
        this.cookieService = cookieService;
    }
    getAllTickets(activeState) {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/basic/all?activeState=" + activeState, { headers: Headers.headers });
    }
    getWorkItemDetails(jobCardNo) {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/job-card-details?jobCardNo=" + jobCardNo, { headers: Headers.headers });
    }
    getDetailsForTicketId(ticketId) {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/details?ticketId=" + ticketId, { headers: Headers.headers });
    }
    getOpenTicketsByVehicle(vehicleNumber) {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/all/" + vehicleNumber, { headers: Headers.headers });
    }
    updateTicket(ticketData) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/details/update", ticketData, { headers: Headers.headers });
    }
    createTicket(ticketFormData) {
        const url = "/api/v1/tickets/details/create";
        return this.http.post(Urls.APP_URL + url, ticketFormData
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
        // }
        // { headers: {...Headers.headers, 'Content-Type': 'multipart/form-data'} }
        );
        // <--
        // return this.http.put<{}>(Urls.APP_URL + '/api/v1/tickets/details/create', ticketData, { headers: Headers.headers });
    }
    loadTickets(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/load-tickets", query, { headers: Headers.headers });
    }
    loadOpsTickets(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/load-ops-tickets", query, { headers: Headers.headers });
    }
    downloadTmsTickets(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/downloadTmsTickets", query, { headers: Headers.headers });
    }
    loadTicketsWithSpecificDateOfService(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/daywise/ticket", query, { headers: Headers.headers });
    }
    loadTicketsCSV(startDate, endDate, project) {
        return this.http.get(Urls.APP_URL +
            "/api/v1/tickets/downloadCSV" +
            "?start_date=" +
            startDate +
            "&end_date=" +
            endDate +
            "&project=" +
            project, { responseType: "blob" });
    }
    getTicketStats(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/stats", query, {
            headers: Headers.headers,
        });
    }
    updateFiles(ticketId, files = []) {
        return this.http.patch(Urls.APP_URL + "/api/v1/tickets/update-files", {
            ticketId,
            files,
        });
    }
    createPhantomRide(driverId, body) {
        return this.http.post(Urls.APP_URL + "/api/v1/create/phantom/ride/" + driverId, body, { headers: Headers.headers });
    }
    reviewDocument(driverId, vehicleNumber, action) {
        return this.http.post(Urls.APP_URL +
            "/api/v1/service/ticket/document/review/" +
            driverId +
            "/" +
            vehicleNumber +
            "/" +
            action, {}, { headers: Headers.headers });
    }
    getReasonList() {
        return this.http.get(Urls.APP_URL + "/api/v1/constant/reasonList");
    }
    getTicketStates(role) {
        return this.http.post(Urls.APP_URL + "/api/v1/ticket-states", role);
    }
    getMaintenanceData() {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/get-maintenance-dp");
    }
    resolveTicket(body) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/resolve/ticket", body, { headers: Headers.headers });
    }
    getDriverPenaltyDetails(leaseDailyStatsId, penaltyName, isDynamicBreakDriver) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/penalty/details?leaseDailyStatsId=" + leaseDailyStatsId
            + "&penaltyType=" + penaltyName + "&isDynamicBreakDriver=" + isDynamicBreakDriver;
        return this.http.get(driver_url, { headers: Headers.headers });
    }
    getLeaseDailyStatsDetails(driverId, slot, incidentDate, penaltyType) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/lease-daily-stats/details?driverId=" + driverId
            + "&slot=" + slot + "&incidentDate=" + incidentDate + "&penaltyType=" + penaltyType;
        return this.http.get(driver_url, { headers: Headers.headers });
    }
    getDriverPenaltySlab(body) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/penalty/slab";
        return this.http.post(driver_url, body, { headers: Headers.headers });
    }
    saveCreditDebitData(body) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/credits/debits/save";
        return this.http.post(driver_url, body, { headers: Headers.headers });
    }
    getReasonCodes(formId) {
        return this.http.get(Urls.APP_URL + "/api/v1/tickets/get-reason-codes?formId=" + formId);
    }
    noIssueUploadImage(file) {
        const formData = new FormData();
        formData.append("files", file);
        return this.http.post(Urls.APP_URL + "/api/v1/document/upload", formData, {
            headers: Headers.headers,
        });
    }
    postCsvTicketsForEmail(query) {
        return this.http.post(Urls.APP_URL + "/api/v1/tickets/send-csv-tickets-mails", query, { headers: Headers.headers });
    }
    updateTmsTicket(ticketData) {
        return this.http.post(Urls.APP_URL + "/api/v1/driver/ticket/update", ticketData, { headers: Headers.headers });
    }
    getDriverDutyDeniedPenaltyDetails(driverId, incidentDate) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/duty-denied/details?driverId=" + driverId
            + "&incidentDate=" + incidentDate;
        return this.http.get(driver_url, { headers: Headers.headers });
    }
    saveDutyDeniedCreditDebitData(body) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/reverse/duty-denied/penalty";
        return this.http.post(driver_url, body, { headers: Headers.headers });
    }
    getDriverAbsenteeismPenaltyDetails(driverId, incidentDate) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/absenteeism/penalty/details?driverId=" + driverId +
            "&incidentDate=" + incidentDate;
        return this.http.get(driver_url, { headers: Headers.headers });
    }
    saveDriverAbsenteeismPenaltyDetails(body) {
        let driver_url = Urls.APP_URL + "/api/v1/tickets/reverse/absenteeism/penalty";
        return this.http.post(driver_url, body, { headers: Headers.headers });
    }
    getVehicleOnlineStatus(vehicleNumber) {
        return this.http.get(Urls.APP_URL + "/api/v1/operations/vehicle?vehicleNumber=" + vehicleNumber);
    }
    getRemarks(params) {
        const url = Urls.APP_URL + '/api/v1/remarks/role';
        return this.http.get(url, { params });
    }
    ;
    uploadDocuments(files) {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        const headers = Headers.headers;
        const url = Urls.APP_URL + "/api/v1/document/upload";
        return this.http.post(url, formData, { headers });
    }
    sendInsuranceEmail(payload) {
        const url = Urls.APP_URL + '/api/v1/tickets/update-tickets';
        return this.http.post(url, payload, { headers: Headers.headers });
    }
    formatTicketDataForInsuranceModal(ticket) {
        const { formData: ticketFormData = {} } = ticket;
        const newFormData = new FormData();
        const forbiddenKeys = ['Repair', 'subIssue'];
        Object.keys(ticketFormData).forEach(key => {
            if (!forbiddenKeys.includes(key)) {
                newFormData.append(key, ticketFormData[key]);
            }
        });
        const accidentalDetails = _.get(ticket, 'insuranceDetails.accidentalDetails', {});
        const vehicleDetails = _.get(ticket, 'insuranceDetails.vehicleDetails', {});
        const legalDetails = _.get(ticket, 'insuranceDetails.legalDetails', {});
        const driverDetails = _.get(ticket, 'insuranceDetails.driverDetails', {});
        const formData = {
            "Date Of Issue": ticketFormData["Date Of Issue"],
            "Vehicle Number": ticketFormData["Vehicle Number"],
            "place": ticketFormData["place"],
            "Description": ticketFormData["Description"],
            "maintenanceDescription": ticketFormData["maintenanceDescription"],
            "incidentStory": ticketFormData["incidentStory"],
            "isInsuranceApplicable": ticketFormData["isInsuranceApplicable"],
            "odometerReading": ticketFormData["odometerReading"],
            "hasInjuries": accidentalDetails['hasInjuries'],
            "injuryTo": accidentalDetails['injuryTo'],
            "Incident Photo/Video": accidentalDetails["files"],
            "Driver": ticketFormData["Driver"],
            "subIssue": ticketFormData["subIssue"],
            "maintenanceRequired": ticketFormData["maintenanceRequired"],
            "fitness": vehicleDetails["fitness"],
            "fitnessDocument": vehicleDetails["fitnessCertificate"],
            "firDocument": legalDetails["firDoc"],
            "medicalProofs": {
                driver: { document: legalDetails["driverMedicalProof"] },
                rider: { document: legalDetails["riderMedicalProof"] },
                "third party": { document: legalDetails["thirdPartyMedicalProof"] },
            },
            "vehicleRC": _.get(ticket, 'insuranceDetails.vehicleDetails.vehicleRc', ''),
            "insuranceDocument": vehicleDetails["insurance"],
            "driverLicense": driverDetails["showDriverDocuments"],
        };
        newFormData.append("category", JSON.stringify(ticket.category));
        newFormData.append("formData", JSON.stringify(formData));
        return newFormData;
    }
}
TicketViewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketViewService_Factory() { return new TicketViewService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: TicketViewService, providedIn: "root" });
