import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TicketCreationService {
    constructor() {
        this.formData = new BehaviorSubject(null);
        this.formDataState$ = this.formData.asObservable();
    }
    set(data) {
        this.formData.next(data);
    }
}
TicketCreationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketCreationService_Factory() { return new TicketCreationService(); }, token: TicketCreationService, providedIn: "root" });
