<p-dialog *ngIf="isModal" [(visible)]="visible" [modal]="isModal" closeOnEscape="true" dismissableMask="true" (onShow)="onShow($event)"
  (onHide)="onHide($event)">

  <h3>Attachments</h3>
  <div class="upload-containers">

    <div *ngFor="let container of uploadContainers; let i = index;" class="container">
      <label for="fileInput" *ngIf="!container && !isUploading[i] && !viewOnly" (click)="storeClickIndex(i)" class="dashed-border">
        <div>
          <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <h4>Add Photo/Video</h4>
      </label>

      <div *ngIf="container && !viewOnly" class="close-icon" (click)="removeMedia(i)">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </div>

      <div *ngIf="!container && viewOnly" class="d-flex justify-content-center flex-column align-items-center dashed-border">
        <div><i class="fa fa-picture-o" aria-hidden="true"></i></div>
        <div>No Preview Found</div>
      </div>

      <div *ngIf="isUploading[i]" class="image-preview dashed-border">
        <ng-container>
          <div class="spinner-border" style="height: 13px; width: 13px; color: white;"  role="status"></div>
          <div style="font-size: 12px; margin-left: 10px; color: white;">Uploading media, Please wait...</div>
        </ng-container>
      </div>

      <img *ngIf="container && isImage(container)" [src]="container" class="image-preview" />
      <video *ngIf="container && !isImage(container)" controls class="image-preview">
        <source [src]="container" type="video/mp4" />
        Browser not supported
      </video>
    </div>
  </div>


  <input type="file" id="fileInput" accept="image/*,video/*" [ngStyle]="{ display: 'none' }" hidden
    (input)="handleFileInput($event)" />
</p-dialog>

<div *ngIf="!isModal && visible">
  <h3>Attachments</h3>
  <div class="upload-containers">

    <div *ngFor="let container of uploadContainers; let i = index;" class="container" style="height: 200px;">
      <label for="fileInput" *ngIf="!container && !isUploading[i] && !viewOnly" (click)="storeClickIndex(i)" class="dashed-border-s">
        <div>
          <i class="fa fa-plus" aria-hidden="true" style="font-size: 20px;"></i>
        </div>
        <h4 style="font-size: 12px;">Add Photo/Video</h4>
      </label>

      <div *ngIf="container && !viewOnly" class="close-icon" (click)="removeMedia(i)">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </div>

      <div *ngIf="!container && viewOnly" class="d-flex justify-content-center flex-column align-items-center dashed-border-s">
        <div><i class="fa fa-picture-o" aria-hidden="true"></i></div>
        <div>No Preview Found</div>
      </div>

      <div *ngIf="isUploading[i]" class="image-preview dashed-border-s">
        <ng-container>
          <div class="spinner-border" style="height: 13px; width: 13px; color: white;"  role="status"></div>
          <div style="font-size: 12px; margin-left: 10px; color: white;">Uploading media, Please wait...</div>
        </ng-container>
      </div>

      <img *ngIf="container && isImage(container)" [src]="container" class="image-preview" style="height: 200px;" />
      <video *ngIf="container && !isImage(container)" controls class="image-preview" style="height: 200px;">
        <source [src]="container" type="video/mp4" />
        Browser not supported
      </video>
    </div>
  </div>


  <input type="file" id="fileInput" accept="image/*,video/*" [ngStyle]="{ display: 'none' }" hidden
    (input)="handleFileInput($event)" />
</div>