import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LocalService } from 'src/app/services/local.service';
import { FormServiceService } from '../../form/form-service.service';
import { CookieService } from 'ngx-cookie-service';
import { Urls } from 'src/app/models/api.constants';
import { CloseModalDataType } from 'src/app/utils/types/ticket';

@Component({
  selector: 'app-close-modal',
  templateUrl: './close-modal.component.html',
  styleUrls: ['./close-modal.component.css']
})
export class CloseModalComponent implements AfterViewInit {

  @Input()
  showModal: boolean = false;

  @Input()
  ticket: any = {}

  @Output()
  onClose = new EventEmitter();

  @Output()
  onSubmit = new EventEmitter<CloseModalDataType>();

  closeForm: FormGroup;

  closeModalMinDate: Date = new Date();
  closeModalMaxDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private msg: MessageService,
    private cookieService: CookieService,
    private formService: FormServiceService,
    public localeService: LocalService
  ) {
    this.closeForm = this.fb.group({
      odometer: [null, Validators.required],
      date: [new Date(), Validators.required]
    });
  }

  ngAfterViewInit() {
    if (!this.ticket || !this.ticket.formData || !this.ticket.formData['Vehicle Number']) {
      this.msg.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Cannot fetch odometer at this moment',
      });

      return;
    }

    // Using global variable to detect change in single click, this is a known issue with primeng
    // Reference: https://github.com/primefaces/primeng/issues/4641
    this.closeModalMinDate = this.localeService.getDate(this.ticket.createdAt);

    const vehicleNumber = this.ticket.formData['Vehicle Number'];
    const savedOdometer = this.cookieService.get(`${vehicleNumber}Odometer`);
    if (savedOdometer) {
      this.closeForm.patchValue({ odometer: savedOdometer });
      return;
    }
    this.populateOdometer(vehicleNumber);
  }

  populateOdometer(vehicleNumber: string) {
    this.formService.getOdometerReading(vehicleNumber).subscribe(({ errorCode, response }) => {
      try {
        if (errorCode !== 200) throw new Error();
        if (!response) return;

        const { comparisonValue = null } = response;
        const expires = new Date();
        expires.setMinutes(expires.getMinutes() + 5);

        this.cookieService.set(`${vehicleNumber}Odometer`, comparisonValue, expires, "/", Urls.COOKIE_URL, null, "Lax");
        this.closeForm.patchValue({ odometer: comparisonValue });
      } catch (error) {
        console.error(error);
        this.cookieService.delete(`${vehicleNumber}Odometer`);
        this.msg.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Cannot fetch odometer at this moment',
        });
      }
    });
  }

  closeModal() {
    this.showModal = false;
    this.onClose.emit(false);
  }

  submitForm() {
    this.onSubmit.emit(this.closeForm.value);
    this.closeModal();
  }

}
