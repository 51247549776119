import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
export class UploadViewModalComponent {
    constructor(ticketViewService, msg) {
        this.ticketViewService = ticketViewService;
        this.msg = msg;
        this.visible = false;
        this.isModal = true;
        this.handleOnShow = new EventEmitter();
        this.handleOnHide = new EventEmitter();
        this.uploadedFiles = new EventEmitter();
        this.uploadContainers = Array(4).fill(null);
        this.viewOnly = false;
        this.isUploading = Array(4).fill(false);
        this.uploadingIndex = -1;
    }
    ngOnInit() {
        this.uploadContainers = this.uploadContainers.concat(new Array(4 - this.uploadContainers.length).fill(null));
    }
    isImage(url) {
        return (url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".svg") ||
            url.endsWith(".jpeg"));
    }
    storeClickIndex(index) {
        this.uploadingIndex = index;
    }
    handleFileInput(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const files = [...event.target.files];
            this.isUploading = Array(4).fill(false);
            this.isUploading[this.uploadingIndex] = true;
            try {
                const { data, errorMessage, code } = yield this.ticketViewService.uploadDocuments(files).toPromise();
                this.isUploading[this.uploadingIndex] = false;
                if (code !== 200)
                    throw new Error(errorMessage);
                const previousValues = this.uploadContainers.filter(url => url);
                const newValues = [...previousValues, ...data];
                this.uploadContainers = newValues.concat(new Array(4 - newValues.length).fill(null));
                this.uploadedFiles.emit(this.uploadContainers);
            }
            catch (error) {
                console.error(error);
                this.isUploading[this.uploadingIndex] = false;
                this.msg.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please try again after sometime',
                });
            }
        });
    }
    onHide(event) {
        this.handleOnHide.emit(event);
        this.visible = false;
    }
    onShow(event) {
        this.handleOnShow.emit(event);
    }
    removeMedia(index) {
        this.uploadContainers[index] = null;
        this.uploadedFiles.emit(this.uploadContainers);
    }
}
