import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TicketViewService } from 'src/app/services/ticket-view.service';

@Component({
  selector: 'app-upload-view-modal',
  templateUrl: './upload-view-modal.component.html',
  styleUrls: ['./upload-view-modal.component.css']
})
export class UploadViewModalComponent implements OnInit {

  @Input()
  visible: boolean = false;

  @Input()
  isModal: boolean = true;

  @Output()
  handleOnShow = new EventEmitter();

  @Output()
  handleOnHide = new EventEmitter();

  @Output()
  uploadedFiles = new EventEmitter();

  @Input()
  uploadContainers: string[] = Array(4).fill(null);

  @Input()
  viewOnly: boolean = false;

  isUploading: boolean[] = Array(4).fill(false);
  uploadingIndex: number = -1;
  

  constructor(
    private ticketViewService: TicketViewService,
    private msg: MessageService,
  ) { }

  ngOnInit() {
    this.uploadContainers = this.uploadContainers.concat(new Array(4 - this.uploadContainers.length).fill(null));
  }

  isImage(url: string) {
    return (
      url.endsWith(".jpg") ||
      url.endsWith(".png") ||
      url.endsWith(".svg") ||
      url.endsWith(".jpeg")
    );
  }

  storeClickIndex(index: number) {
    this.uploadingIndex = index;
  }

  async handleFileInput(event) {
    const files = [...event.target.files];
    this.isUploading = Array(4).fill(false);
    this.isUploading[this.uploadingIndex] = true;

    try {
      const { data, errorMessage, code } = await this.ticketViewService.uploadDocuments(files).toPromise();
      this.isUploading[this.uploadingIndex] = false;
      if (code !== 200) throw new Error(errorMessage)

      const previousValues = this.uploadContainers.filter(url => url);
      const newValues = [...previousValues, ...data];
      
      this.uploadContainers = newValues.concat(new Array(4 - newValues.length).fill(null));
      this.uploadedFiles.emit(this.uploadContainers);
    } catch (error) {
      console.error(error);
      this.isUploading[this.uploadingIndex] = false;
      this.msg.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please try again after sometime',
      });
    }
  }

  onHide(event) {
    this.handleOnHide.emit(event);
    this.visible = false;
  }
  onShow(event) {
    this.handleOnShow.emit(event);
  }

  removeMedia(index: number) {
    this.uploadContainers[index] = null;
    this.uploadedFiles.emit(this.uploadContainers);
  }

}
