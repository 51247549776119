import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';

@Injectable({
  providedIn: "root",
})
export class DriversService {
  constructor(private http: HttpClient) {}

  getAllDrivers(): Observable<{}> {
    return this.http.get<{}>(Urls.HUB_URL + "/api/v1/drivers/all", {
      headers: Headers.headers,
    });
  }

  getDriverDetails(
    body: { search: string; sort: string },
    params: { [key: string]: string } = { skip: "0", limit: "50" }
  ): Observable<{}> {
    const url = "/api/v1/drivers/paginated/details";
    return this.http.post<{}>(Urls.HUB_URL + url, body, {
      headers: Headers.headers,
      params,
    });
  }
}
