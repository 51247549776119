import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
export class InsuranceModalComponent {
    constructor(fb, odoInsurance, driverService, ticketViewService, msgService, ticketCreationState) {
        this.fb = fb;
        this.odoInsurance = odoInsurance;
        this.driverService = driverService;
        this.ticketViewService = ticketViewService;
        this.msgService = msgService;
        this.ticketCreationState = ticketCreationState;
        this.visible = false;
        /**
         * Using variable to store values inserted previously in ticket form
         */
        this.formData = {};
        this.handleOnHide = new EventEmitter();
        this.handleSubmit = new EventEmitter();
        this.medicalProofs = [];
        this.medicalProofsObject = {
            'driver': { loader: false, document: '' },
            'rider': { loader: false, document: '' },
            'third party': { loader: false, document: '' }
        };
        this.accidentMedia = [];
        this.uploadModalVisibility = false;
        /**
         * Documents related to insurance like RC, Insurance and previously uploaded fitness
         */
        this.insuranceDetails = {};
        /**
         * Documents related to driver like DLs etc.
         */
        this.driverDocuments = {};
        this.isFirUploading = false;
        this.firDocument = '';
        this.maxDate = new Date();
        this.form = this.fb.group({
            incidentDescription: ['', Validators.required],
            dateTime: ['', Validators.required],
            place: ['', Validators.required],
        });
    }
    populateForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.formData)
                return;
            this.form.patchValue({
                incidentDescription: this.formData.get('incidentStory'),
                dateTime: new Date(this.formData.get('Date Of Issue')),
                place: this.formData.get('place')
            });
            const formData = JSON.parse(this.formData.get('formData') || '{}');
            this.medicalProofs = formData.injuryTo || [];
            ['driver', 'rider', 'third party'].forEach(mp => {
                this.medicalProofsObject[mp] = {
                    loader: false,
                    document: _.get(formData, `medicalProofs[${mp}].document`, ''),
                };
            });
            this.accidentMedia = formData['Incident Photo/Video'] || [];
            this.firDocument = formData['firDocument'];
            this.odoInsurance.driverDetails$.subscribe(driverDocuments => {
                this.driverDocuments = driverDocuments || {};
            });
            this.odoInsurance.odoInsure$.subscribe(({ response }) => {
                this.insuranceDetails = response || {};
            });
        });
    }
    ngOnInit() {
        this.ticketCreationState.formDataState$.subscribe(res => {
            this.formData = res;
            this.populateForm();
        });
    }
    getDriverIdAndName() {
        const formData = JSON.parse(this.formData.get('formData') || '{}');
        const attr = formData['Driver'];
        const seperation = (attr || '').split(',');
        return { punchId: seperation[1], name: seperation[0] };
    }
    onHide(event) {
        this.handleOnHide.emit(event);
        this.visible = false;
    }
    toggleUploadModalVisibility(value) {
        this.uploadModalVisibility = value;
    }
    getUploadFiles(files) {
        this.accidentMedia = files;
    }
    closeModal() {
        this.visible = false;
        this.handleOnHide.emit(false);
    }
    uploadFIR(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isFirUploading = true;
            const fir = [...event.target.files];
            try {
                const { data, errorMessage, code } = yield this.ticketViewService.uploadDocuments(fir).toPromise();
                if (code !== 200)
                    throw new Error(errorMessage);
                this.firDocument = data.pop();
                this.isFirUploading = false;
            }
            catch (error) {
                console.error(error);
                this.isFirUploading = false;
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please try again after sometime',
                });
            }
        });
    }
    uploadMedicalProof(event, party) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const files = [...event.target.files];
            this.medicalProofsObject[party].loader = true;
            try {
                const { data, errorMessage, code } = yield this.ticketViewService.uploadDocuments(files).toPromise();
                if (code !== 200)
                    throw new Error(errorMessage);
                this.medicalProofsObject[party].loader = false;
                this.medicalProofsObject[party].document = data.pop();
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please try again after sometime',
                });
                this.medicalProofsObject[party].loader = false;
            }
        });
    }
    ;
    handleModalSubmit() {
        if (!this.form.valid) {
            this.msgService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please enter valid details',
            });
            return;
        }
        if (!this.medicalProofs.every(proof => this.medicalProofsObject[proof].document)) {
            this.msgService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please add remaining medical documents',
            });
            return;
        }
        const formData = Object.assign({}, JSON.parse(this.formData.get('formData') || '{}'));
        const proofs = {};
        this.medicalProofs.forEach(mp => {
            proofs[mp] = this.medicalProofsObject[mp];
        });
        formData['Incident Photo/Video'] = this.accidentMedia.filter(m => m);
        formData['firDocument'] = this.firDocument;
        formData['medicalProofs'] = proofs;
        formData['injuryTo'] = this.medicalProofs;
        formData['vehicleRC'] = this.insuranceDetails.rcURL;
        formData['insuranceDocument'] = this.insuranceDetails.insuranceURL;
        if (this.driverDocuments['Driving License (Front)'] && this.driverDocuments['Driving License (Front)'].url) {
            formData['driverLicense'] = this.driverDocuments['Driving License (Front)'].url || '';
        }
        formData.incidentStory = this.form.get('incidentDescription').value;
        formData['Date Of Issue'] = this.form.get('dateTime').value;
        formData['place'] = this.form.get('place').value;
        this.formData.set('incidentStory', this.form.get('incidentDescription').value);
        this.formData.set('Date Of Issue', this.form.get('dateTime').value);
        this.formData.set('place', this.form.get('place').value);
        this.formData.set('formData', JSON.stringify(formData));
        this.handleSubmit.emit(this.formData);
    }
    getKeyFromFormData(key) {
        return JSON.parse(this.formData.get('formData') || '{}')[key];
    }
}
