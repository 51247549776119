<p-dialog [(visible)]="showModal" [modal]="true" [dismissableMask]="true" (onHide)="closeModal()" class="close-modal">
  <form [formGroup]="closeForm" class="close-form">
    <img src="assets/blue-car.svg" class="car-img" />
    <h1>Close Ticket</h1>

    <div>
      <label for="odometer">
        Odometer
        <span>*</span>
      </label>
      <input type="number" formControlName="odometer" pInputText placeholder="Enter Odometer" />

      <label for="date" class="mt-3">
        Closure Date
        <span>*</span>
      </label>
      <p-calendar appendTo="body" placeholder="Enter date of closure" formControlName="date" [showIcon]="true"
        [showTime]="true" [minDate]="closeModalMinDate" [maxDate]="closeModalMaxDate"></p-calendar>
    </div>

    <button 
      type="button" 
      pButton 
      class="submit mt-4 justify-content-center" 
      (click)="submitForm()"
      label="Submit"
      [disabled]="closeForm.status !== 'VALID'"
    >
    </button>
  </form>
</p-dialog>