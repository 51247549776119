import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OdometerAndInsuranceState {

  private odometerAndInsurance: BehaviorSubject<any> = new BehaviorSubject(null);
  odoInsure$: Observable<any> = this.odometerAndInsurance.asObservable();

  private driverDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  driverDetails$: Observable<any> = this.driverDetails.asObservable();

  constructor() { }

  setOdometerAndInsurance(data: any) {
    this.odometerAndInsurance.next(data);
  }

  setDriverDetails(data: any) {
    this.driverDetails.next(data);
  }
}
