import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class OdometerAndInsuranceState {
    constructor() {
        this.odometerAndInsurance = new BehaviorSubject(null);
        this.odoInsure$ = this.odometerAndInsurance.asObservable();
        this.driverDetails = new BehaviorSubject(null);
        this.driverDetails$ = this.driverDetails.asObservable();
    }
    setOdometerAndInsurance(data) {
        this.odometerAndInsurance.next(data);
    }
    setDriverDetails(data) {
        this.driverDetails.next(data);
    }
}
OdometerAndInsuranceState.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OdometerAndInsuranceState_Factory() { return new OdometerAndInsuranceState(); }, token: OdometerAndInsuranceState, providedIn: "root" });
