<div class="media-wrapper">
  <label for="incidentMedia">{{ label }}</label>
  <br />

  <button
    type="button"
    class="incdent-upload-cta"
    (click)="handleUploadClick()"
  >
    <i class="fa fa-paperclip" aria-hidden="true"></i>
    {{ getButtonText() }}
  </button>
</div>

<app-upload-view-modal
  [visible]="visible"
  (handleOnHide)="handleOnHide($event)"
  (uploadedFiles)="getUploadFiles($event)"
></app-upload-view-modal>