<app-detail-ticket-view [ticketId]="ticketId" (backToTicket)="isDetailViewVisible = false" *ngIf="isDetailViewVisible">
</app-detail-ticket-view>
<div class="tms-wrapper" *ngIf="!isDetailViewVisible">
  <p-dataView #dv [value]="allTicketsBasicData" [paginator]="true" [rows]="limit" [totalRecords]="totalRecords"
    paginatorPosition="bottom" filterBy="brand" [lazy]="true" (onLazyLoad)="searchWithFilters($event)"
    [loading]="!isLoadComplete">
    <p-header>
      <div class="header-box">
        <div style="display: flex; gap: 10px;">
          <img src="../../../assets/car.svg" alt="">

          <div style="padding: 0px 15px;
          border-left: 1px solid #AAA;">
            <div class="tickets"> Tickets</div>
            <div class="total-tickets">
              Total tickets : {{ totalTickets }}
            </div>
          </div>
        </div>

        <div style="display: flex; gap: 10px;">

          <div *ngIf="currentProject == 'operationsTMS'" class="search">
            <i class="fa fa-search"></i>
            <input style=" 
               background: #4a4848;
               border-radius: 2px !important;
               height: 30px;
               font-size: 12px;
               border: 0px;
               border-radius: 8px;
               color: white;
               outline: none;
             
          " type="text" placeholder="Search by ticket ID/Vehicle no." [ngModel]="selectedTicketId"
              (ngModelChange)="modelChangeFn($event)" id="searchInput" />
          </div>

          <div *ngIf="currentProject != 'operationsTMS'" class="search">
            <input style="
        background: #353434;
        border-radius: 2px !important;
        height: 30px;
        font-size: 12px;
        border: 0.5px solid #929090;
        border-radius: 8px;
        color: white;
        " type="text" [(ngModel)]="search" id="searchInput" placeholder="Search..." />
          </div>

          <div *ngIf="currentProject != 'operationsTMS'">
            <p-dropdown [options]="activeState" [(ngModel)]="isActive" placeholder="Select Category"></p-dropdown>

          </div>

          <div *ngIf="
      currentUserSsoId === '119' && currentProject != 'operationsTMS'
    ">
            <p-multiSelect [options]="usersList" [(ngModel)]="selectedAssignedForAction"
              defaultLabel="Users with Action">
            </p-multiSelect>
          </div>
          <div *ngIf="
      currentUserSsoId === '119' && currentProject != 'operationsTMS'
    ">
            <p-multiSelect [options]="usersList" [(ngModel)]="selectedAssignedForView" defaultLabel="Users with View">
            </p-multiSelect>
          </div>

          <div *ngIf="currentProject != 'operationsTMS'">
            <button pButton type="button" label="Search" (click)="searchWithFilters()"></button>
          </div>
          <div style="display: flex" *ngIf="currentProject == 'operationsTMS'">
            <div style="
        height: 30px;
        border-radius: 2px;
        display: flex;
        background-color: #4a4848;
      ">
              <i *ngIf="!hideFlag" class="fa fa-flag" style="
          color: white;
          position: relative;
          margin-top: 8px;
          font-size: 12px;
          margin-left: 12px;
        " aria-hidden="true"></i>
              <p-dropdown [options]="colorTag" placeholder="Colour tag" (onChange)="colorChange($event)" [(ngModel)]="colour">
                <ng-template let-item pTemplate="selectedItem">
                  <i class="fa fa-flag" [ngStyle]="changeStyle(item.value)" aria-hidden="true"></i>
                  {{ item.label }}
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <i class="fa fa-flag" [ngStyle]="changeStyle(item.value)" aria-hidden="true"></i>
                  {{ item.label }}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div *ngIf="currentProject == 'operationsTMS'" style="display: flex">
            <div style="height: 30px; display: flex; background: #353434">
              <button style="
      border-radius: 2px 0px 0px 2px;
      background: #4b4848;
      width: 30px;
      height: 30px;
      border: none;
    ">
                <i class="fa fa-calendar" style="color: white" aria-hidden="true"></i>
              </button>
              <p-calendar [maxDate]="maxDateTime" class="dateButton" placeholder="select date range" appendTo="body"
                [(ngModel)]="date" (onSelect)="dateSelectClickHandler()" [showIcon]="false" selectionMode="range"
                autoWidth="false" [style]="{
      width: '167px',
      height: '30px'  }">
              </p-calendar>
              <button style="
      border-radius: 0px 2px 2px 0px;
      background: #4b4848;
      width: 41px;
      height: 30px;
      border: none;
    " (click)="removeFilter()">
                <i class="fa fa-times" aria-hidden="true" style="color: white"></i>
              </button>
            </div>
          </div>

          <button (click)="postCsvTicketsForEmail()" type="text" class="csv-button" [disabled]
            style="height: 30px; width: 120px;  border: 1px solid #116fbf; background: white; ">
            <div class="header-text-button">
              <ng-container *ngIf="showCsvText">
                <!--   <img class="me-2 mb-1" src="../../../../assets/send-mail.svg" height="12px" style="background: white;" /> -->
                <span
                  style="font-size: 12px; font-weight: 400;   color: #116fbf;  background: white; white-space: nowrap;">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i> Send as CSV</span>
              </ng-container>
              <ng-container *ngIf="showCsvLoader">
                <div class="spinner-border" style="height: 13px; width: 13px; color: black;white-space: nowrap;"
                  role="status"></div>
                <span style="font-size: 12px; margin-left: 10px; color: black;">Loading</span>
              </ng-container>

              <ng-container *ngIf="showCsvSuccess">
                <i class="fa fa-check-circle"
                  style="color: green; background: white; font-size: 13px;white-space: nowrap;"></i>
                <span style="font-size: 12px; margin-left: 6px; color: black">Sent to email</span>
              </ng-container>

              <ng-container *ngIf="showCsvFailed">
                <i class="fa fa-times-circle"
                  style="color: #ff3b30; background: white; font-size: 14px;white-space: nowrap;"></i>
                <span style="font-size: 12px; margin-left: 5px; color: black">Technical error</span>
              </ng-container>
            </div>
          </button>

          <div *ngIf="showResetFilter()" (click)="resetAllFilters()">
            <button type="text" class="clear-filters">
                <ng-container>
                    <span class="gap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                            fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7.79518 5.88096L11.5 1.5H3.41422L7.79518 5.88096ZM7.50001 8.41421L5.50001 6.41421V9.5L7.50001 10.5V8.41421Z"
                                fill="#116fbf" />
                            <path d="M1 1L10.5 11" stroke="#116fbf" stroke-linecap="round" />
                        </svg>Reset all Filters</span>
                </ng-container>

            </button>
        </div>
        </div>
      </div>
      <div class="table-grid" *ngIf="currentProject=='operationsTMS'">
        <p-table #dt1 [value]="allTicketsBasicData" dataKey="punchId" [rows]="10" scrollHeight="60vh"
          [scrollable]="true">
          <ng-template pTemplate="header" class="container">
            <tr>
              <th class="header" style="width: 5%;">
                <div class="header-items">SR.No.</div>
              </th>
              <th class="header" style="width: 15%;">
                <div class="header-items">Ticket ID & Vehicle no.</div>
              </th>
              <th class="header" style="width: 15%;">
                <div class="header-items">Ticket category
                  <span class="filterDropdown">
                    <p-multiSelect [ngClass]="{'filter1': category.length}" [options]="categoryDataMapping[country]"
                      [(ngModel)]="category" appendTo="body" defaultLabel="All" [dropdownIcon]="'pi pi-filter'"
                      (onChange)="filterCategory($event)"></p-multiSelect></span>
                </div>
              </th>
              <th class="header" style="width: 15%;">
                <div class="header-items">Ticket status
                  <span class="filterDropdown">
                    <p-multiSelect [ngClass]="{'filter2': currentStage.length}" [options]="statusData"
                      [(ngModel)]="currentStage" appendTo="body" defaultLabel="All" [dropdownIcon]="'pi pi-filter'"
                      (onChange)="filterStatus($event)"></p-multiSelect>
                  </span>
                </div>
              </th>
              <th class="header" style="width: 15%;">
                <div class="header-items">Issues
                  <span class="filterDropdown">
                    <p-multiSelect [options]="issuesOptions" [ngClass]="{'filter3': issues.length}" [(ngModel)]="issues"
                      appendTo="body" defaultLabel="All" [dropdownIcon]="'pi pi-filter'"
                      (onChange)="filterIssues($event)"></p-multiSelect>
                  </span>
                </div>
              </th>
              <th class="header" style="width: 12%;">
                <div class="header-items">Hub Name
                  <span class="filterDropdown" *ngIf="showHubFilter">
                    <p-multiSelect [options]="hubsOptions" [ngClass]="{'filter4': selectedHubs.length}"
                      [(ngModel)]="selectedHubs" appendTo="body" defaultLabel="All" [dropdownIcon]="'pi pi-filter'"
                      (onChange)="filterHubs($event)"></p-multiSelect>
                  </span>
                </div>
              </th>
              <th class="header" style="width: 11%;">
                <div class="header-items">Assignee
                  <span class="filterDropdown">
                    <p-multiSelect [options]="expense[country]" [ngClass]="{'filter5': role.length}" [(ngModel)]="role"
                      appendTo="body" defaultLabel="All" [dropdownIcon]="'pi pi-filter'"
                      (onChange)="roleFilter($event)"></p-multiSelect>
                  </span>
                </div>
              </th>
              <th style="width: 12%;">Last Updated At</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-ticket let-i="rowIndex">
            <tr class="tr">
              <td (click)="getDetailViewForTicket(ticket.ticketId)" style="width: 5%;">
                {{ i + 1 }}
              </td>
              <td (click)="getDetailViewForTicket(ticket.ticketId)" style="width: 15%;">
                <div>{{ ticket.ticketId }}</div>
                <div style="margin-top: 10px;"> {{
                  ticket.formData
                  ? ticket.formData["Vehicle Number"]
                  ? ticket.formData["Vehicle Number"]
                  : ""
                  : ""
                  }}
                  <i class="fa fa-flag" [ngStyle]="{'color': ticket.colour}"></i>
                </div>
              </td>
              <td style="width: 15%;" (click)="getDetailViewForTicket(ticket.ticketId)">{{ ticket.category[1] }}</td>

              <td style="width: 15%;" (click)="getDetailViewForTicket(ticket.ticketId)">
                <span *ngIf="
                    ticket.currentStage != 'Car sent to workshop' &&
                    ticket.currentStage != 'Repaired at Hub'
                  ">{{ ticket.currentStage }}</span>
                <span *ngIf="
                    ticket.currentStage == 'Car sent to workshop' ||
                    ticket.currentStage == 'Repaired at Hub'
                  ">{{
                  ticket.jobCardStatus ? ticket.jobCardStatus : ticket.currentStage
                  }}</span>

                <div *ngIf="ticket.remainingTime && ticket.currentStage != 'Ticket Closed' && localeService.isLocaleIndia()" class="hourglass"
                  [ngStyle]="{'background-color': getBackgroundColor(ticket)}" pTooltip="Remaining time : {{ticket.remainingTime}}
                   Ideal TAT : {{ticket.TAT}}
                  Last Updated At : {{ticket.updatedAt | amTimeAgo:timezone}}">
                  <i class="fa fa-hourglass-end" aria-hidden="true"></i>
                  <span>{{ticket.remainingTime}}</span>
                </div>

              </td>
              <td style="width: 15%;" (click)="openIssueBox(ticket)" *ngIf="ticket.originalSubIssue"
                style="color: #719EFF !important;cursor: pointer; width: 14%;text-decoration: underline;">{{
                getIssueType(ticket)
                }}</td>

              <td style="width: 15%;" *ngIf="!ticket.originalSubIssue">
                {{ getIssueType(ticket)}}
              </td>
              <td (click)="getDetailViewForTicket(ticket.ticketId)" style="width: 12%;">{{ (ticket.hubId && hubList[ticket.hubId]) ?
                hubList[ticket.hubId]["name"] : ""
                }}</td>
              <td (click)="getDetailViewForTicket(ticket.ticketId)" style="width: 11%;">
                {{
                ticket.role && ticket.role?.length > 0 ? ticket.role[0] : ""
                }}
              </td>
              <td style="width: 12%;">{{ticket.updatedAt | amTimeAgo: timezone}}</td>
            </tr>


          </ng-template>
        </p-table>
      </div>
    </p-header>

  </p-dataView>
</div>
<p-dialog header="Tickets CSV" [(visible)]="display" [style]="{ width: '40%', padding: '30px' }"
  (onHide)="hideDialogBox()">
  <div style="display: inline-flex">
    <div class="inside-cell">
      <span class="ui-float-label">
        <p-calendar [(ngModel)]="start_date" appendTo="body" [showIcon]="true" inputId="icon"></p-calendar>
        <label for="float-input">Start Date</label>
      </span>
    </div>
    <div class="inside-cell">
      <span class="ui-float-label">
        <p-calendar [(ngModel)]="end_date" appendTo="body" [showIcon]="true" inputId="icon"></p-calendar>
        <label for="float-input">End Date</label>
      </span>
    </div>
    <button (click)="downloadCSV()" class="download_btn">Download</button>
  </div>
</p-dialog>

<p-dialog [(visible)]="displaySubIssues" [style]="{ maxWidth: '30%' }">
  <div class="center issue-box">
    <div style="font-weight:700">Issue details</div>
    <div class="mt-4">{{issueDetails?.ticketId}} | {{issueDetails?.formData["Vehicle Number"]}}</div>
  </div>

  <div class="issue-box">
    <div *ngFor="let item of issueDetails?.originalSubIssue; let i = index" class="mb-4">
      <div>
        <span style="font-weight: 700;">Issue {{i + 1}}</span> - {{item?.issueName}}
      </div>
      <div style="font-size:12px" class="mt-1"><span style="font-weight: 600;">Sub Issues -
        </span>{{getSubIssueNames(item?.subIssues)}}</div>
    </div>
    <div>
      <div style="font-weight: 600;">Description - </div>
      <div class="mt-1" style="font-size: 12px;">{{issueDetails?.formData["Description"]}}</div>
    </div>
  </div>
</p-dialog>