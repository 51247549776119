
    <div
      class="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <file-upload
        [formControlName]="config.name || 'files'"
        [label]="config.label"
        [inputId]="config.htmlId"
        [multiple]="config.multiple"
        [className]="config.className"
        [accept]="config.accept"
        (getValidFiles)="getValidFiles($event)"
      >
      </file-upload>
    </div>
  