<p-dialog [(visible)]="visible" [modal]="true" [closeOnEscape]="false" [dismissableMask]="true" [closable]="false"
  [showHeader]="false" (onHide)="onHide($event)" [style]="{ maxWidth: '50%' }" class="custom-dialog">
  <div class="insurance-modal">
    <div class="block">
      <h1>Insurance Documents</h1>
      <h3>Verify the documents from insurance</h3>
    </div>

    <div class="block">
      <h1 class="mb-3">
        Vehicle Details
        <span class="details">
          Vehicle Number : {{ formData.get("Vehicle Number") }}
        </span>
      </h1>

      <h2>
        <i class="fa fa-eye" aria-hidden="true"></i>
        View Docs :

        <a *ngIf="insuranceDetails && insuranceDetails.rcURL" [href]="insuranceDetails.rcURL" class="ml-4 border-right pr-4">
          <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
          Vehicle RC
        </a>

        <a *ngIf="insuranceDetails && insuranceDetails.insuranceURL" [href]="insuranceDetails.insuranceURL" class="ml-4 border-right pr-4">
          <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
          Insurance
        </a>

        <a *ngIf="getKeyFromFormData('fitnessDocument')" [href]="getKeyFromFormData('fitnessDocument')" class="ml-4">
          <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
          Fitness Certificate
        </a>
      </h2>
    </div>

    <div class="block">
      <h1 class="mb-3">
        Driver Details
        <span class="details">
          Punch ID : {{ getDriverIdAndName().punchId }} &nbsp;
          Driver Name : {{ getDriverIdAndName().name }}
        </span>
      </h1>

      <h2>
        <i class="fa fa-eye" aria-hidden="true"></i>
        View Docs :

        <a *ngIf="driverDocuments && driverDocuments['Driving License (Front)'] && driverDocuments['Driving License (Front)'].url"
          [href]="driverDocuments['Driving License (Front)'].url" class="ml-4">
          <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
          Driver DL
        </a>
      </h2>
    </div>

    <div class="block">
      <div class="d-flex justify-content-between align-items-center">
        <h1>Incident Details</h1>
        <button class="btn-outline-white" (click)="toggleUploadModalVisibility(!uploadModalVisibility)">
          <i *ngIf="!uploadModalVisibility || !accidentMedia.length" class="fa fa-upload" aria-hidden="true"></i>
          <i *ngIf="accidentMedia.length && uploadModalVisibility" class="fa fa-eye-slash" aria-hidden="true"></i>
          <span *ngIf="!accidentMedia.length">Upload accident Photo/Video</span>
          <span *ngIf="accidentMedia.length">{{ uploadModalVisibility ? 'Hide' : 'View' }} accident Photo/Video</span>
        </button>
      </div>

      <app-upload-view-modal [visible]="uploadModalVisibility" [uploadContainers]="accidentMedia" [isModal]="false"
        (handleOnHide)="toggleUploadModalVisibility(false)"
        (uploadedFiles)="getUploadFiles($event)"></app-upload-view-modal>

      <form [formGroup]="form">
        <label for="incidentDescription">Incident Description</label>
        <span class="text-danger">&nbsp;*</span>
        <br />
        <input type="text" placeholder="Enter incident description" class="dark-input mb-2" id="incidentDescription"
          formControlName="incidentDescription" />

        <div class="d-flex mb-2 justify-content-between align-items-center" style="flex-wrap: wrap;">

          <div style="flex: 1;">
            <label for="dateAndTime">Date & Time of Incident</label>
            <span class="text-danger">&nbsp;*</span>
            <br />
            <p-calendar formControlName="dateTime" placeholder="Enter Date & Time" hourFormat="12" [showTime]="true"
              [showIcon]="true" appendTo="body" class="drop" [maxDate]="maxDate"></p-calendar>
          </div>

          <div style="flex: 1;">
            <label for="place">Place</label>
            <span class="text-danger">&nbsp;*</span>
            <br />
            <input type="text" placeholder="Enter place of incident" class="dark-input" id="place"
              formControlName="place" />
          </div>
        </div>
      </form>
    </div>

    <div class="block">
      <h1 class="mb-3">Legal Details</h1>
      <h2>Docs</h2>
      <label *ngIf="!isFirUploading && !firDocument" for="firUpload"
        class="btn-outline-white">
        <i class="fa fa-upload" aria-hidden="true"></i>
        FIR
      </label>
      <input type="file" accept="image/*,application/pdf" id="firUpload" [ngStyle]="{ display: 'none' }" hidden
        (input)="uploadFIR($event)" />
      <span *ngIf="isFirUploading">
        <ng-container>
          <span class="spinner-border" style="height: 13px; width: 13px; color: white; margin-right: 8px;"  role="status"></span>
          <span style="font-size: 12px; margin-left: 10px; color: white;">Uploading media, Please wait...</span>
        </ng-container>
      </span>
      <a *ngIf="firDocument" [href]="firDocument">
        <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
        View FIR Document
      </a>

      <h2 class="mt-3 d-flex align-items-center" style="gap: 16px;">
        Medical Proof:
        <p-checkbox name="medicalProof" value="driver" label="Driver" [(ngModel)]="medicalProofs"></p-checkbox>
        <p-checkbox name="medicalProof" value="rider" label="Rider" [(ngModel)]="medicalProofs"></p-checkbox>
        <p-checkbox name="medicalProof" value="third party" label="Third Party"
          [(ngModel)]="medicalProofs"></p-checkbox>
      </h2>

      <div class="d-flex mt-3" style="gap: 12px;">
        <div *ngFor="let mp of medicalProofs" class="mr-2">
          <label *ngIf="!medicalProofsObject[mp].document" class="btn-outline-white" for="{{mp}}MedicalProof">
            <span *ngIf="!medicalProofsObject[mp].loader">
              <i class="fa fa-upload" aria-hidden="true"></i>
              {{ mp | titlecase }} Medical Proof
            </span>
            <span *ngIf="medicalProofsObject[mp].loader">
              <ng-container>
                <span class="spinner-border" style="height: 13px; width: 13px; color: white; margin-right: 8px;"  role="status"></span>
                <span style="font-size: 12px; margin-left: 10px; color: white;">Uploading Document</span>
              </ng-container>
            </span>
          </label>
          <input type="file" accept="image/*,application/pdf" id="{{mp}}MedicalProof" [ngStyle]="{ display: 'none' }" hidden
            (input)="uploadMedicalProof($event, mp)" />
          <a *ngIf="medicalProofsObject[mp].document" [href]="medicalProofsObject[mp].document" class="pr-4">
            <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
            View {{ mp | titlecase }} Proof
          </a>
        </div>
      </div>
    </div>

    <div class="block d-flex justify-content-end" style="gap: 12px;">
      <button class="btn-outline-white" (click)="closeModal()">Cancel</button>
      <button type="button" (click)="handleModalSubmit()" class="btn-submit">Submit</button>
    </div>
  </div>
</p-dialog>